export function myDate() {
    //获取当前时间
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    if (month < 10) {
        month = "0" + month;
    }
    if (day < 10) {
        day = "0" + day;
    }
    var nowDate = year + "-" + month + "-" + day;
    return nowDate;
}

export function getMonth(index) {
    //获取当前时间
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    if (index === 1) {
        if (month === 12) {
            month = 1;
        } else {
            month = month + 1;
        }
    }
    if (month < 10) {
        month = "0" + month;
    }

    var nowDate = year + "-" + month + "-01";
    if (index === 2) {
        nowDate = year + "-" + month;
    }
    return nowDate;
}

//日期格式化
export function parseTime(time, cFormat) {
    if (arguments.length === 0) {
        return null;
    }
    // const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s} {a}'
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
    let date;
    if (typeof time === 'object') {
        date = time;
    } else {
        if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
            time = parseInt(time);
        }
        if (typeof time === 'number' && time.toString().length === 10) {
            time = time * 1000;
        }
        date = new Date(time);
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    };
    const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key];
        if (key === 'a') {
            return ['日', '一', '二', '三', '四', '五', '六'][value];
        }
        if (result.length > 0 && value < 10) {
            value = '0' + value;
        }
        return value || 0;
    });
    return timeStr;
}
/**
 * 时间转换为XX前
 */
export function clickDateDiff(value) {
    var result;
    var minute = 1000 * 60;
    var hour = minute * 60;
    var day = hour * 24;
    var month = day * 30;
    var now = new Date().getTime();
    var diffValue = parseInt(now) - parseInt(value);
    if (diffValue < 0) {
        return;
    }
    var monthC = diffValue / month;
    var weekC = diffValue / (7 * day);
    var dayC = diffValue / day;
    var hourC = diffValue / hour;
    var minC = diffValue / minute;
    if (monthC >= 1) {
        result = "" + parseInt(monthC) + '月前';
    } else if (weekC >= 1) {
        result = "" + parseInt(weekC) + '周前';
    } else if (dayC >= 1) {
        result = "" + parseInt(dayC) + '天前';
    } else if (hourC >= 1) {
        result = "" + parseInt(hourC) + '小时前';
    } else if (minC >= 1) {
        result = "" + parseInt(minC) + '分钟前';
    } else {
        result = '刚刚';
    }
    return result;
}
