<template>
  <div class="phonebox" style="">
    <div class="phonetitle">设备报表</div>
    <div class="phonecx" style=" margin-top: 0px;">
      <div class="top-item">
        <div class="title">
          快速选择
        </div>
        <div class="data" v-for="(item,indey) in pickerOptions.shortcuts" :class="value2.toString()==item.onClick('kk').map(itey => parseTime(itey, '{y}-{m}-{d}')).toString()?'hover':''" :key="item.text" @click="clicktime(item,indey)">{{item.text}}</div>
      </div>
      <div class="top-item">
        <div class="title">
          选择时间
        </div>
        <el-date-picker value-format="yyyy-MM-dd" @change="datack" v-model="value2" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
        </el-date-picker>

      </div>

      <el-select class="phoneinput" style="width:75%;margin-right: 10px; " v-model="iot_id" placeholder="请选择机器">
        <el-option label="全部" value=" ">
        </el-option>
        <el-option v-for="item in deviceList" :key="item.iot_id" :label="item.nick_name" :value="item.iot_id">
        </el-option>
      </el-select>

      <el-button type="primary" @click="cx">查询</el-button>
    </div>
    <div class="phoneMain">
      <el-table :data="list" border style="width: 100%;margin-top: 20px;">
        <el-table-column prop="start_time" label="日期" min-width="15%">
          <template slot-scope="scope">

            <span> {{dayjs(scope.row.start_time).format('MM-DD')}}</span>
            <!-- <span style="color:#52ea07;" v-else>开机</span> -->

          </template>

        </el-table-column>

        <el-table-column prop="iot_id" label="设备" :formatter="swiot_id" min-width="20%"></el-table-column>
        <el-table-column prop="material_name" label="物料" min-width="15%"></el-table-column>
        <el-table-column prop="work_procedure" label="工序" min-width="15%"></el-table-column>
        <el-table-column prop="num" label="数量" min-width="15%"></el-table-column>
        <el-table-column label="工作时间" min-width="20%">
          <template slot-scope="scope">

            <span>{{dateConvert((getTimes(scope.row.end_time) -  getTimes(scope.row.start_time)))}}</span>

          </template>

        </el-table-column>
        <!-- <el-table-column prop="total_price" label="总价"></el-table-column> -->

      </el-table>
      <div class="pagbox">
        <el-pagination :pager-count='5' @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="page" :page-sizes="[5,10, 50, 100]" :page-size="limit" layout="sizes, prev, pager, next" :total="total">
        </el-pagination>
      </div>

    </div>

    <div class="phoneFoot">
      <div class="footbox ">
        <router-link :to="{name:'phoneProductionOrder'}"> 生产订单</router-link>
      </div>
      <div class="footbox ">
        <router-link :to="{name:'phoneSchedule'}"> 生产排程</router-link>
      </div>
      <div class="footbox">
        <router-link :to="{name:'home'}"> 实时看板</router-link>
      </div>
      <div class="footbox active">
        <router-link :to="{name:'phoneReport'}"> 设备报表</router-link>
      </div>
      <div class="footbox">
        <router-link :to="{name:'phoneStaffreport'}"> 员工报表</router-link>
      </div>
      <div class="footbox">

        <router-link :to="{name:'phoneconsu'}"> 耗材库存</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { parseTime } from '@/utlis/date.js';
import { getreport, deviceList } from "@/service/api";
export default {
  data () {
    return {
      list: [],
      parseTime,
      page: 1,
      limit: 10,
      queritem: '',
      status: '',
      name: '',
      deviceList: [],
      iot_id: '',
      total: 0,
      pickerOptions: {
        shortcuts: [{
          text: '三天',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);

            if (picker !== 'kk') {
              picker.$emit('pick', [start, end]);
              return [start, end];
            } else {
              return [start, end];
            }

          }
        }, {
          text: '一周',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            if (picker !== 'kk') {
              picker.$emit('pick', [start, end]);
              return [start, end];
            } else {
              return [start, end];
            }
          }
        }, {
          text: '半个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
            if (picker !== 'kk') {
              picker.$emit('pick', [start, end]);
              return [start, end];
            } else {
              return [start, end];
            }
          }
        }, {
          text: '一个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            if (picker !== 'kk') {
              picker.$emit('pick', [start, end]);
              return [start, end];
            } else {
              return [start, end];
            }
          }
        }]
      },
      value2: []
    };
  },
  methods: {
    xalert () {
      alert('开发中');
    },
    getTimes (item) {
      let newitme = '';
      var u = navigator.userAgent;
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isiOS) {
        newitme = new Date(item.replace(/-/g, '/')).getTime();
      } else {
        // 安卓系统
        newitme = new Date(item).getTime();
      }
      return newitme;
      // return (new Date(item).getTime());
    },
    dateConvert (value) {
      let hour,
        min,
        sec,
        createDate = value / 1000;
      hour = parseInt(createDate / 60 / 60);
      min = parseInt((createDate - hour * 3600) / 60);
      sec = parseInt(createDate - (hour * 3600 + min * 60));
      if (hour > 0) {
        if (min > 0) {
          if (sec > 0) {
            createDate = hour + "时" + min + "分" + sec + "秒";
          } else {
            createDate = hour + "时" + min + "分";
          }
        } else {
          if (sec > 0) {
            createDate = hour + "时" + sec + "秒";
          } else {
            createDate = hour + "时";
          }
        }
      } else if (min > 0) {
        createDate = sec > 0 ? min + "分" + sec + "秒" : min + "分";
      } else if (sec > 0) {
        createDate = sec + "秒";
      }
      return createDate;
    },
    clicktime (item,) {

      this.value2 = item.onClick(this).map(item => parseTime(item, '{y}-{m}-{d}'));
      console.log(this.value2);
      this.start_date = this.value2[0];
      this.end_date = this.value2[1];
      this.getreport();

    },
    datack (time) {
      console.log(time);
      this.start_date = time[0];
      this.end_date = time[1];
      this.getreport();
    },
    getreport () {
      let data = {
        limit: this.limit,
        page: this.page,
        iot_id: this.iot_id,
        start_date: this.start_date,
        end_date: this.end_date
      };
      getreport(data).then(res => {
        console.log(res);
        this.list = res.data.list;
        this.total = res.data.total;
      });
    },
    swiot_id (row) {
      let text = '';

      this.deviceList.forEach(item => {
        if (item.iot_id === row.iot_id) {
          text = item.nick_name;
        }

      });
      return text;
    },
    cx () {
      this.page = 1;
      this.getreport();
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
      this.limit = val;
      this.getreport();
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getreport();
    },

    getdeviceList () {
      deviceList({ limit: 999, page: 1 }).then(res => {

        this.deviceList = res.data.list;

      });
    }
  },
  mounted () {
    this.getreport();
    this.getdeviceList();
  }
};
</script>
<style scoped>
@import './css/index.css';
.top-item {
  display: flex;
  align-items: center;
  padding: 0 15px;
  text-align: left;
  margin-top: 10px;
}
.data {
  margin: 0 0px;

  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  line-height: 30px;
  width: 60px;
  margin-right: 5px;
  display: flex;
  background-color: #b5b8bf;
  align-items: center;
  justify-content: center;
}
.title {
  margin-right: 5px;
  font-size: 14px;
  font-weight: 700;
  width: 80px;
}
.top-item .hover {
  background: #28bb4f;
}
</style>